.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(13, 104, 240);
}

.App-link {
  color: #61dafb;
}

.p-select{
  padding: 10px 30px !important;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
.input_n::-webkit-outer-spin-button,
.input_n::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input_n[type=number] {
  -moz-appearance: textfield;
}
.ax{
     color: black;
     text-decoration: none;
}
.lottery_number_item{
     background-color: aliceblue;
     margin-top: 10px;
     padding: 10px 0;
}

.lottery_number_box{
     overflow-y: auto;
}
*{
     box-sizing: border-box;
     margin: 0;
     padding: 0;
}
body{
     background-color: #F1F4FF;
}
.top_bar{
     height: 10vh;
     display: flex;
     align-items: center;
     padding: 0 50px;
}
.top_bar h1{
     margin-left: 20px;
     font-size: 25px;
}
.brand_logo{
     width: 35px;
     height: 35px;
}
.d-inline{
     display: inline;
}
.tc{
     color: #070F59;
}

/* main box */
.main_box{
     padding: 0 100px;
     height: 90vh;
     display: flex;
     justify-content: space-between;
     align-items: center;
}
.main_box div h1{
     font-size: 50px;
}
.download_btn{
     border: none;
     font-size: 14px;
     background: linear-gradient(90deg, #3D30EF, #783BFA);
     color: white;
     padding: 15px 50px;
     border-radius: 40px;
     margin-top: 60px;
     box-shadow: 2px 20px 30px #c1bee6;
     cursor: pointer;
}
.add_demo{
     width: 550px;
}

/* media queries */

@media screen and (max-width:1200px){
     .add_demo {
          width: 350px;
     }
}

@media screen and (max-width:992px){
     .main_box{
          padding: 0 80px;
     }
     .main_box div h1 {
          font-size: 34px;
     }
     .download_btn {
          font-size: 12px;
          margin-top: 50px;
     }
}

@media screen and (max-width: 768px){
     .main_box{
          flex-direction: column-reverse;
          justify-content: space-around;
          height: auto;
          padding: 50px 0;
     }
     .main_box_div{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
     }
     .add_demo {
          width: 80%;
     }
     .main_box_div:nth-child(1){
          margin-top: 50px;
     }

     .top_bar {
          padding: 0 30px;
     }
     
}
