/* Chrome, Safari, Edge, Opera */
.input_n::-webkit-outer-spin-button,
.input_n::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input_n[type=number] {
  -moz-appearance: textfield;
}